<template>
  <div class="home page">
    <div class="banner">
      <h1 class="banner__text">Выдающиеся личности Дагестана</h1>
    </div>
    <div class="container">
      <div class="loader" v-if="!popular">
        <img src="@/assets/images/ajax.gif" alt="">
      </div>
      <section class="home-section" v-else>
        <div class="home-section__top">
          <h2 class="home-section__title">Популярное</h2>
        </div>
        <div class="persons-list">
          <router-link 
            to="/person" 
            class="person-link" 
            v-for="person in popular"
          >
            <div class="person" @click="$emit('openPerson', person.id)">
              <div class="person__image">
                <img :src="person.img" alt="" v-if="person.img">
                <img src="@/assets/images/empty-img.png" alt="" class="empty-image" v-else>
              </div>
              <div class="person__info">
                <span class="person__name">{{person.title}}</span>
                <span class="person__dob" v-if="person.born">{{person.born}} <i v-if="person.died">-</i> {{person.died}}</span>
              </div>
            </div>
          </router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      popular: '',
    }
  },
  mounted() {
    fetch('https://person.lezgikim.ru/api.php?do=getPostList&key=156dfdd335hjkodS')
    .then(response => response.json())
    .then(json => {
      this.popular = json
    })
  }
}
</script>

<style lang="scss">
  @import "../assets/css/home.scss";
</style>
