import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// import axios from 'axios'
// import VueAxios from 'vue-axios'

// Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

let play = document.getElementById("play");
console.log(play);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
