<template>
  <div id="app">
    <div class="nav">
      <div class="nav__container">
        <router-link to="/categories" class="nav__link nav__categories"></router-link>
        <router-link to="/" class="nav__link nav__home"></router-link>
        <router-link to="/about" class="nav__link nav__about"></router-link>
      </div>
    </div>
    <div class="install-prompt" :class="{show: installPrompt}">
      <strong>Установите приложение Дагестанцы</strong>
      <p>Нажмите <span class="safari-share"></span> и выберите <br> на экран "Домой"</p>
      <button class="btn install-prompt__close" @click="closeInstallPrompt">&times;</button>
    </div>
    <router-view
      v-bind:categoryId="categoryId"
      v-bind:categoryName="categoryName"
      v-bind:personId="personId"
      @openPerson="getPersonId"
      @openCategory="getCategoryId"
    />
  </div>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {
      persons: null,
      categoryId: null,
      categoryName: null,
      personId: null,
      installPrompt: false,
    }
  },
  methods: {
    getPersonId(id) {
      this.personId = id
    },
    getCategoryId(category) {
      this.categoryId = category.id
      this.categoryName = category.name
    },
    closeInstallPrompt() {
      this.installPrompt = false
    }
  },
  mounted() {
    var user = detect.parse(navigator.userAgent);
    if (user.browser.family == 'Mobile Safari') {
      if ('standalone' in navigator && !navigator.standalone && (/iphone/gi).test(navigator.platform) && (/Safari/i).test(navigator.appVersion)) {
        setTimeout(() => {
          this.installPrompt = true
        }, 2000)
      }
    }
  }
}
</script>

<style lang="scss">
  @import "./assets/css/index.scss";
</style>
